import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/SEO';

const IndexPage = () => {
    return (
        <Layout>
            <SEO
                title="Cute & Tender"
                keywords={[
                    `gatsby`,
                    `cute and tender`,
                    `tienda en linea`,
                    `ropa de bebes`,
                ]}
            />
            <StaticQuery
                query={query}
                render={data => (
                    <div className="main-container">
                        <h1 style={{ display: 'none' }}>Cute & Tender</h1>
                        <Img
                            style={{
                                maxWidth: '450px',
                                margin: '0 auto',                                
                            }}
                            fluid={data.logo.childImageSharp.fluid}
                        />
                        <p
                            style={{
                                fontFamily: 'MohrRounded-Bold',
                                fontSize: '26px',
                            }}
                        >
                            para mundos pequeños
                        </p>
                    </div>
                )}
            />
        </Layout>
    );
};

const query = graphql`
    query IndexQuery {
        logo: file(relativePath: { eq: "logo-border.png" }) {
            childImageSharp {
                fluid(maxWidth: 900) {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
    }
`;

export default IndexPage;
